<script setup>

const leasing = useLeasingParams();
const { onWindowEvent } = useWindowEvents();
const { breakpoint } = useBreakpoints();

const expandPage = (expand) => {
  const app = document.documentElement;

  expand
    ? app.classList.add('group','body-expanded')
    : app.classList.remove('group','body-expanded')
}

onWindowEvent('resize',() => {
  expandPage(leasing.leasingDrawerOpened.value);
})

watch(
  () => leasing.leasingDrawerOpened.value,
  (opened) => {
    expandPage(opened);
  }
)

</script>

<template>
  <AppHeaderMinimal>
    <div class="py-2">
      <CarFinancing />
    </div>
  </AppHeaderMinimal>
  <slot />
  <AppFooterMinimal />
  <Blend />
</template>
